<template>
  <div class="editor-meeting">
    <!-- <icy-header title="编辑会议内容"></icy-header> -->
    <app-title class="add-m-t" title="会议的讨论议题"></app-title>
    <!-- 我的困惑是，我如何 -->
    <div class="cell-box">
      <div class="fs24 fw400 fc666">我的困惑是，我如何</div>
      <div class="inner fs28 dfr cross-center main-between">
        <van-field
          clearable
          v-model="q1"
          placeholder="如“做好新项目的定位和运营”"
        />
      </div>
    </div>
    <!-- 这个问题是重要的，因为 -->
    <div class="cell-box">
      <div class="fs24 fw400 fc666">这个问题是重要的，因为</div>
      <div class="inner fs28 dfr cross-center main-between">
        <van-field
          clearable
          v-model="q2"
          placeholder="如“关系到新项目是否能够成功”"
        />
      </div>
    </div>
    <!-- 到目前为止，为了解决这个问题我已经做了 -->
    <div class="cell-box">
      <div class="fs24 fw400 fc666">到目前为止，为了解决这个问题我已经做了</div>
      <div class="inner fs28 dfr cross-center main-between">
        <van-field
          clearable
          v-model="q3"
          placeholder="如“市场调研，招募了运营人员等”"
        />
      </div>
    </div>
    <!-- 我希望小组帮助我的是 -->
    <div class="cell-box">
      <div class="fs24 fw400 fc666">我希望小组帮助我的是</div>
      <div class="inner fs28 dfr cross-center main-between">
        <van-field
          clearable
          v-model="q4"
          placeholder="如“提供更好的运营方法等”"
        />
      </div>
    </div>

    <app-title class="add-m" title="图片附件"></app-title>
    <!-- 图片附件 -->
    <div class="uploader-box dfr flex-wrap">
      <div v-for="(item, index) in uploadImgs" :key="index" class="uploadImgs">
        <img class="upload-img w100 h100 object-cover" :src="item" alt="" />
        <div @click="deleteImgs(index)" class="delete box fs0">
          <img class="w100 h100" src="../assets/images/delete.png" alt="" />
        </div>
      </div>
      <div class="uploadImgs">
        <van-uploader
          class="uploader-vant"
          :max-count="10"
          preview-size="2.12rem"
          multiple
          :upload-icon="uploadIcon"
          :after-read="afterRead"
        />
      </div>
    </div>

    <div class="bottom-btn">
      <icy-button @click.native="save">保存</icy-button>
    </div>
  </div>
</template>

<script>
import { uploadImg } from "../utils/util";

export default {
  data() {
    return {
      id: "",
      fileList: [],
      uploadImgs: [],
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      uploadIcon: require("../assets/images/meeting.png"),
    };
  },
  created() {
    this.$title("编辑会议详情");
    this.id = this.$route.query.id;
    this.initInfo();
  },
  methods: {
    deleteImgs(index) {
      this.uploadImgs.splice(index, 1);
    },
    async initInfo() {
      const resp = await this.$API
        .get("meetingDetail", { id: this.id })
        .then((resp) => resp);
      if (resp.code == 1000) {
        this.q1 = resp.data.questions[0];
        this.q2 = resp.data.questions[1];
        this.q3 = resp.data.questions[2];
        this.q4 = resp.data.questions[3];
        this.uploadImgs = resp.data.files;
        resp.data.files.forEach((item) => {
          this.fileList.push({ url: item.url });
        });
      }
    },
    // 保存
    save() {
      this.sendSave();
    },
    async sendSave() {
      const data = {
        id: this.id,
        questions: [this.q1, this.q2, this.q3, this.q4],
        files: this.uploadImgs,
      };
      const resp = await this.$API
        .post("meetingEdit", data)
        .then((resp) => resp);
      if (resp.code == 1000) {
        this.$toast.success("编辑会议内容成功");
        this.$back();
      }
    },
    /**
     * 轻提示
     */
    toastFun(msg) {
      this.toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        loadingType: "spinner",
        message: msg,
      });
    },
    afterRead(file) {
      uploadImg(file, (url) => {
        this.getUploadContent(url)
      })
    },
    getUploadContent(url) {
      this.uploadImgs.push(url);
    },
    deleteImg(index) {
      this.uploadImgs.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.uploadImgs {
  width: 2.12rem;
  height: 2.12rem;
  position: relative;
  margin-right: 0.21rem;
  margin-bottom: 0.21rem;
  box-sizing: border-box;
  overflow: hidden;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.26rem;
    height: 0.26rem;
  }
  .uploader-vant {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.editor-meeting {
  height: auto;
  .van-cell {
    padding: 0 0;
  }
  .cell-box {
    width: 6.78rem;
    margin-left: 0.36rem;
    border-bottom: 0.01rem solid #eee;
    margin-top: 0.36rem;
    .inner {
      min-height: 0.4rem;
      margin-top: 0.2rem;
      .placeholder {
        color: #cccccc;
      }
    }
  }
  .add-m-t {
    margin-top: 0.36rem;
    margin-left: 0.36rem;
  }
  .add-m {
    margin-top: 0.8rem;
    margin-left: 0.36rem;
  }
  .uploader-box {
    margin: 0.36rem 0 1.5rem 0.36rem;
  }
  .bottom-btn {
    width: 100%;
    padding: 0.2rem 0.36rem;
    margin-top: 0.2rem;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    z-index: 99;
  }
}
</style>
